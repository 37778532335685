import React, { useState, useRef, useEffect } from 'react';
import { useLocalStorage, writeStorage } from '@rehooks/local-storage';
import { GoogleLogout, GoogleLogin } from 'react-google-login';

import useFormSubmit from './Hooks/useFormSubmit';
import { nanoid } from 'nanoid';
import './App.css';
const clientId = '366645757207-uqpn6mjs9u64qi05f0ufjjv5pka8ql0i.apps.googleusercontent.com';
const ModalMessage = ({ btn1, btn2 }) => (
  <>
    <div
      className="modal fade show d-block"
      id="exampleModalCenter"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="messageModalCenterTitle"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        style={{ maxWidth: 400 }}
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalCenterTitle">
              Удаление
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => btn1()}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
         
          <div className="modal-body">
            <br />
            <br />
            <br />
            <h5 className="font-weight-normal text-center">
              Данные будут безвозвратно удалены.<br /><br />
              Продолжить?
            </h5>
            <br />
            <br />
          </div>
          <div className="modal-footer border-0 mb-3">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={() => btn1()}
            >
              Отмена
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => btn2()}
            >
              Удалить
            </button>
          </div>
        </div>
      </div>
    </div>
    <div className="modal-backdrop fade show" />
  </>
);
const hashCode = s => s.split('').reduce((a,b) => (((a << 5) - a) + b.charCodeAt(0))|0, 0);

const enabledHash = [1234222, -1577518349];

const ShowTrees = ({
    node: { id, lastname, firstname, surname, birthday, comment, images, parents, relative },
    click,
    level,
    selectedId,
    changeInputs,
    isEdit,
    deleteNodeClb,
    setIsShowForm,
    setIsShowMessage,
}) => (
  <span className="position-relative">
    <table
      className={`table table-borderless border mx-3 float-left${id === selectedId ? ' border-success border-4' : ''}`}
      onClick={(e) => {
        // e.stopPropagation();
        // changeInputs({ lastname: '', firstname: '', surname: '', birthday: '', comment:'',  relative: '' });
        // click(id);
        // isEdit(false);
      }
    }>
      <tbody>
        <tr className="">
          <td className="text-left font-weight-bold table-primary" colSpan="2">
            {level}. Колено
            <button
              type="button"
              className="ml-5 p-1"
              onClick={(e) => {
                e.stopPropagation();
                changeInputs({ lastname: '', firstname: '', surname: '', birthday: '', comment:'',  relative: '' });
                click(id);
                isEdit(false);
                setIsShowForm(true)
              }}
            ><img alt="Добавить связь" width="30" src="/images/icons/user-plus.svg" /></button>
            <button
              type="button"
              className="ml-3 p-1"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                changeInputs({ lastname, firstname, surname, birthday, comment, relative });
                click(id);
                isEdit(true);
                setIsShowForm(true)
              }}
            ><img alt="Редактировать" width="30" src="/images/icons/users.svg" /></button>
            <button
              type="button"
              className="ml-3 p-1"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                // deleteNodeClb(id);
                click(id);
                setIsShowMessage(true);
              }}
            ><img alt="Удалить связь" width="30" src="/images/icons/user-minus.svg" /></button>
          </td>
        </tr>
        <tr className="">
          <td className="text-left" style={{ width: 150 }}>Фамилия</td><td className="text-left font-weight-bold">{lastname}</td>
        </tr>
        <tr className="">
          <td className="text-left" style={{ width: 150 }}>Имя</td><td className="text-left font-weight-bold">{firstname}</td>
        </tr>
        <tr className="">
          <td className="text-left" style={{ width: 150 }}>Отчество</td><td className="text-left font-weight-bold">{surname}</td>
        </tr>
        <tr className="">
          <td className="text-left" style={{ width: 150 }}>День рождения</td><td className="text-left font-weight-bold">{birthday}</td>
        </tr>
        <tr className="">
          <td className="text-left" style={{ width: 150 }}>Отношение</td><td className="text-left font-weight-bold">{relative}</td>
        </tr>
        <tr className="">
          <td className="text-left" style={{ width: 150 }}>Комментарий</td><td className="text-left font-weight-bold" style={{ maxWidth: 350 }}>{comment}</td>
        </tr>
        <tr className="">
          <td colSpan="2">
            {parents && parents.length > 0 && parents.map(item => (
              <ShowTrees key={item.id}
                node={item}
                level={level + 1}
                click={click}
                selectedId={selectedId}
                changeInputs={changeInputs}
                isEdit={isEdit}
                deleteNodeClb={deleteNodeClb}
                setIsShowForm={setIsShowForm}
                setIsShowMessage={setIsShowMessage}
                />))}
          </td>
        </tr>
      </tbody>
    </table>
  </span>
);

const relatives = [
  'Выберите...',
  'мама',
  'папа',
  'бабушка',
  'дедушка',
  'брат',
  'сестра',
  'дядя',
  'тётя',
  'пра-бабушка',
  'пра-дедушка',
  '2 пра-бабушка',
  '2 пра-дедушка',
  '3 пра-бабушка',
  '3 пра-дедушка',
  '4 пра-бабушка',
  '4 пра-дедушка',
];

function App() {
  const [ familyTree, setFamilyTree ] =
    useState(
      useLocalStorage('familyTree', {
        id: '1',
        firstname: 'Валерия', 
        surname: 'Валерьевна', 
        lastname: 'Филипец', 
        birthday: '22.08.2005',
        relative: '',
        comment: '',
        images: [],
        parents: [],
      })[0]
    );
  const [ confFamilyTree, setConfFamilyTree ] = useState('')
  const [ parentId, setParentId ] = useState('1');
  const [ isEdit, setIsEdit ] = useState(false);
  const [ isShowConfig, setIsShowConfig ] = useState(false);
  const [ isShowForm, setIsShowForm ] = useState(false);
  const [ isShowMessage, setIsShowMessage ] = useState(false);
  const [ isLoggedIn, setLoggedIn ] = useState(false);
  const elTextArea = useRef(null);
  const initialstate = {
    id: '',
    lastname: '',
    firstname: '',
    surname: '',
    birthday: '',
    relative: '',
    comment: '',
    images: [],
    parents: [],
  };
  const signup = () => {
    const currentTree =Object.assign({}, familyTree);
    const addNode = (id, root, obj) => {
      if (root.id === id) {
        root.parents.push(obj);
        return;
      }
      root.parents.map(item => addNode(id, item, obj));
    };

    const changeNode = (id, root, obj) => {
      if (root.id === id) {
        root.lastname = obj.lastname;
        root.firstname = obj.firstname;
        root.surname = obj.surname;
        root.birthday = obj.birthday;
        root.relative = obj.relative;
        root.comment = obj.comment;
        return;
      }
      root.parents.map(item => changeNode(id, item, obj));
    };

    if (!isEdit) {
      if (inputs.firstname.trim().length === 0 && inputs.lastname.trim().length === 0)
        return;
      addNode(parentId, currentTree, { 
        id: nanoid(),
        firstname: inputs.firstname, 
        surname: inputs.surname, 
        lastname: inputs.lastname, 
        birthday: inputs.birthday,
        relative: inputs.relative,
        comment: inputs.comment,
        parents: [],
        images: [],
      });
    } else {
      changeNode(parentId, currentTree, { 
        id: parentId,
        firstname: inputs.firstname, 
        surname: inputs.surname, 
        lastname: inputs.lastname, 
        birthday: inputs.birthday,
        relative: inputs.relative,
        comment: inputs.comment,
      });
    }
    setFamilyTree(currentTree);
    // writeStorage('familyTree', currentTree);
    setIsShowForm(false);

    console.log('familyTree', familyTree);
    console.log('JSON.stringify', JSON.stringify(familyTree));
    console.log('currentTree', currentTree);
  };
  const {inputs, changeInputs, handleInputChange, handleSubmit} = useFormSubmit(initialstate, signup);
  const deleteNode = (id, root) => {
    // if (root.id === id) {
    //   return;
    // }
    return { id: root.id, lastname: root.lastname, 
      firstname: root.firstname, surname: root.surname,
      birthday: root.birthday, comment: root.comment, 
      images: root.images, relative: root.relative,
      parents: root.parents.map(item => deleteNode(id, item)).filter(item => item.id !== id),
    };
  };

  const deleteNodeClb = (id) => {
    const currentTree =Object.assign({}, familyTree);
    setFamilyTree(deleteNode(id, currentTree));
    // writeStorage('familyTree', familyTree)
  };

  useEffect(() => {
    writeStorage('familyTree', familyTree);
  },
  [familyTree]);

  return (
    <>
      {isLoggedIn ? (
        <div className="App">
          <header className="App-header">
            Семейное дерево
            <button
              type="button"
              className="btn-config p-1"
              onClick={(e) => {
                setIsShowConfig(prev => !prev);
                setConfFamilyTree(JSON.stringify(familyTree));
              }}
            >
              <img alt="Настройка" width="30" src="/images/icons/setup.svg" />
          </button>
          </header>
          {isShowMessage && (
            <ModalMessage btn1={() => setIsShowMessage(false)} btn2={() => {deleteNodeClb(parentId); setIsShowMessage(false)}} />
          )}
          {isShowConfig && (
            <>
              <textarea
                ref={elTextArea}
                className="w-100 form-control"
                rows="20"
                onChange={e => setConfFamilyTree(e.target.value)}
                value={confFamilyTree}
              />
              <button
                className="btn btn-primary mt-3"
                type="submit"
                onClick={() => {
                  const current = JSON.parse(confFamilyTree);
                  setFamilyTree(current);
                  // writeStorage('familyTree', current);
                  setIsShowConfig(false);
                  console.log('current', current)
                }}
              >
                Применить
              </button>
            </>
          )}
          {!isShowConfig && (
            <main>
              {isShowForm && (
                <>
                  <div className={`modal fade show${isShowForm ? ' d-block' : ''}`} id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalCenterTitle">
                            {!isEdit ? 'Новые данные' : 'Редактирование' }
                          </h5>
                          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setIsShowForm(false)}>
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <form 
                            className="form" onSubmit={handleSubmit}>
                            <div className="form-groupl">
                              <label>Фамилия</label>
                              <input className="form-control" name="lastname" type="text" onChange={handleInputChange} value={inputs.lastname} />
                            </div>
                            <div className="form-group">
                              <label>Имя</label>
                              <input className="form-control" name="firstname" type="text" onChange={handleInputChange} value={inputs.firstname} />
                            </div>
                            <div className="form-group">
                              <label>Отчество</label>
                              <input className="form-control" name="surname" type="text" onChange={handleInputChange} value={inputs.surname} />
                            </div>
                            <div className="form-group">
                              <label>День рождения</label>
                              <input className="form-control" name="birthday" type="text" onChange={handleInputChange} value={inputs.birthday} />
                            </div>
                            <div className="form-group">
                              <label>Отношение</label>
                              <select name="relative" className="form-control" onChange={handleInputChange} value={inputs.relative}>
                                {relatives.map(item => (<option value={item} key={item}>{item}</option>))}
                              </select>
                              {/* <input className="form-control" name="comment" type="text" onChange={handleInputChange} value={inputs.comment} /> */}
                            </div>
                            <div className="form-group">
                              <label>Комментарий</label>
                              {/* <input className="form-control" name="comment" type="text" onChange={handleInputChange} value={inputs.comment} /> */}
                              <textarea className="form-control" name="comment" rows="5" onChange={handleInputChange} value={inputs.comment} />
                            </div>
                            <button type="button" className="btn btn-secondary mr-5" data-dismiss="modal" onClick={() => setIsShowForm(false)}>Отмена</button>
                            <button type="submit" className="btn btn-primary ml-5">Сохранить</button>
                          </form>
                          
                        </div>
                        {/* <div className="modal-footer">
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="modal-backdrop fade show" />
                </>
              )}

              <section>
                <ShowTrees key={familyTree.id}
                  node={familyTree}
                  click={setParentId}
                  selectedId={parentId}
                  level={1}
                  changeInputs={changeInputs}
                  isEdit={setIsEdit}
                  deleteNodeClb={deleteNodeClb}
                  setIsShowForm={setIsShowForm}
                  setIsShowMessage={setIsShowMessage}
                />
              </section>
            </main>
          )}
        </div>
      ) : (
      <>
         <GoogleLogin 
          isSignedIn={true}
          onSuccess={(response) => {
            const { profileObj: {
              email,
              familyName,
              givenName,
              googleId,
              imageUrl,
              name,
            } } = response;
            // console.log(response);
            setLoggedIn(enabledHash.some(item => item === hashCode(email)));
          }} 
          onFailure={() => {}} 
          clientId={clientId}
          onRequest={ (response) => {
            console.log(response);
          }}
          responseGoogle={ (response) => {
            console.log(response);
          }}
        />
      </>
      )}
    </>
 );
}

export default App;
