import { useState, useCallback } from 'react';

const useFormSubmit = (initialstate, callback) => {
  const [inputs, setInputs ] = useState(initialstate);

  const changeInputs = (initialstate) => {
    setInputs(initialstate);
  };

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    callback();
  };
  
  const handleInputChange = useCallback(
    ({ target: { name, value } }) => {
      console.log('name', name)
      console.log('value', value)
      setInputs((inputs) => ({
        ...inputs,
        [name]: value,
      }));
    },
    [],
  );

  return {
    handleSubmit,
    handleInputChange,
    inputs,
    changeInputs,
  };
};

export default useFormSubmit;
